import svgInject from '@iconfu/svg-inject'

export default {
  install (App) {
    App.directive('svg-inject', {
      beforeMount (el) {
        el.onload = function () {
          svgInject(this)
        }
      },
    })
  },
}
