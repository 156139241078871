export default class BrowserStatus {
  constructor (callback) {
    this._callback = callback
    this._isHidden = false
    this._windowProperty = false
    this.init()
  }

  init () {
    let eventName

    if (typeof document.hidden !== 'undefined') {
      this._windowProperty = 'hidden'
      eventName = 'visibilitychange'
    } else if (typeof document.msHidden !== 'undefined') {
      this._windowProperty = 'msHidden'
      eventName = 'msvisibilitychange'
    } else {
      console.debug('Unable to appStatus in this browser')
      return
    }

    this._hidden = document[this._windowProperty]

    document.addEventListener(eventName, () => {
      if (this._hidden !== document[this._windowProperty]) {
        this._hidden = document[this._windowProperty]
        this._callback(!this._hidden)
      }
    })
  }
}
