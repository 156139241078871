export const asset = (path) => {
  if (process.env.VUE_APP_ASSET_URL) {
    return `${process.env.VUE_APP_ASSET_URL}${path}`
  }

  return path
}

export default {
  methods: {
    $asset: asset,
  },
}
