import { ref } from 'vue'
import BrowserStatus from './appStateAdapters/BrowserStatus'
import { BridgeRepository } from '~gro-modules/Bridge'
import { Singleton } from '~gro-helpers'

class AppStatus extends Singleton {
  constructor () {
    super(AppStatus, (instance) => {
      instance.inForeground = ref(true)
    })
  }

  install (App, Adapter = BrowserStatus) {
    new Adapter((status) => {
      this.inForeground.value = status
    })

    Object.defineProperty(App.config.globalProperties, '$bridge', {
      get: () => BridgeRepository.current,
    })

    Object.defineProperty(App.config.globalProperties, '$onsiteBridge', {
      get: () => BridgeRepository.current?.isOnsite(),
    })

    Object.defineProperty(App.config.globalProperties, '$virtualBridge', {
      get: () => BridgeRepository.current?.isVirtual(),
    })

    Object.defineProperty(App.config.globalProperties, '$connected', {
      get: () => {
        const bridge = BridgeRepository.current
        if (!bridge) return false
        return bridge.connected
      },
    })
  }
}

export default new AppStatus()
