export default {
  data: () => ({ timeouts: [] }),
  methods: {
    async $wait (seconds) {
      return await new Promise((resolve) => this.timeouts.push(setTimeout(resolve, seconds * 1000)))
    },
  },
  beforeUnmount () {
    this.timeouts.forEach(timeout => {
      clearTimeout(timeout)
    })
  },
}
