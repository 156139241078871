import {
  actionSheetController,
  alertController,
  menuController,
  modalController,
  popoverController,
  toastController,
} from '@ionic/vue'
import { Translator } from '~gro-plugins'
import Dropdown from '~gro-components/Dropdown'
import { useRouter } from 'vue-router'

const router = useRouter()
export const dialogActive = async () => {
  if (await modalController.getTop()) return true
  return false
}

export const toast = async (messageTag, duration = 4000, buttons = null) => {
  const toast = await toastController.create({
    message: Translator.translate(messageTag),
    cssClass: 'gro-toast',
    duration,
    buttons,
  })
  await toast.present()
  return toast
}

export const alert = async (messageTag, options = {}) => {
  let resolvePromise = null
  const promise = new Promise(resolve => {
    resolvePromise = resolve
  })
  const {
    headerTag = 'common.Alert',
    confirmTag = 'common.Ok',
    dismissTag = 'common.Dismiss',
    dismissible = false,
    permanent = false,
  } = options

  const buttons = [{
    text: Translator.translate(confirmTag),
    role: 'confirm',
    handler () {
      resolvePromise(true)
      return !permanent
    },
  }]
  if (dismissible && !permanent) {
    buttons.unshift({
      text: Translator.translate(dismissTag),
      role: 'cancel',
      handler () {
        resolvePromise(false)
      },
    })
  }
  const alert = await alertController.create({
    message: Translator.translate(messageTag),
    header: Translator.translate(headerTag),
    backdropDismiss: !permanent && dismissible,
    buttons,
  })

  await alert.present()
  return promise
}

export const confirm = async (messageTag, ...args) => {
  const alert = await alertController.create({
    message: Translator.translate(messageTag, ...args),
    header: Translator.translate('common.Please confirm'),
    buttons: [
      {
        text: Translator.translate('common.Cancel'),
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: Translator.translate('common.Yes'),
        role: 'confirm',
      },
    ],
  })

  await alert.present()
  const result = await alert.onDidDismiss()
  return result.role === 'confirm'
}

export const prompt = async (messageTag, inputs, confirmTag = 'common.Ok', headerTag = null) => {
  const alert = await alertController.create({
    header: headerTag ? Translator.translate(headerTag) : null,
    message: Translator.translate(messageTag),
    backdropDismiss: false,
    cssClass: 'egro-alert',
    inputs,
    buttons: [
      {
        text: Translator.translate('common.Cancel'),
        role: 'cancel',
        cssClass: 'secondary',
      }, {
        text: Translator.translate(confirmTag),
        role: 'confirm',
      },
    ],
  })

  await alert.present()
  const result = await alert.onDidDismiss()
  if (result.role === 'cancel') return {}
  return result.data.values
}

export const actionSheet = async (buttonDefinitions = []) => {
  const sheet = await actionSheetController
    .create({
      cssClass: 'egro-action-sheet',
      buttons: buttonDefinitions.map(definition => {
        return {
          handler: () => {
            router.push(definition.route, definition.routeParams ?? {})
          },
          // icon: Translator.translate('common.Add'),
          ...definition,
        }
      }),
    })
  await sheet.present()
  return sheet
}

export const showError = async (error, duration = 4000) => {
  const toast = await toastController.create({
    message: error,
    duration,
  })
  await toast.present()
  return toast
}

export const showGraphQLErrors = async (errors) => {
  if (!errors || !errors.length) {
    return
  }
  errors.forEach(async (error) => {
    if (error.extensions && error.extensions.validation) {
      for (const key of Object.keys(error.extensions.validation)) {
        await showError(error.extensions.validation[key])
      }
    } else if (
      error.extensions &&
      error.extensions.error &&
      Translator.hasKey('common.errors.' + error.extensions.error)
    ) {
      await showError(Translator.translate('common.errors.' + error.extensions.error))
    } else {
      await showError('An error occurred. Please try again.')
    }
  })
}

export const openMenu = (menu = null) => {
  return menuController.open(menu)
}
export const closeMenu = async () => {
  await menuController.close()
  // document.getElementById('main').classList.remove('menu-content-open')
}

export const showInModal = async (component, componentProps = {}) => {
  const modal = await modalController.create({
    component,
    componentProps,
  })
  await modal.present()
  await modal.onDidDismiss()
}

export const showInPopover = async (event, component, componentProps = {}, options = {}) => {
  const popover = await popoverController.create({
    component,
    componentProps: {
      ...componentProps,
      dismiss: () => {
        popover.dismiss()
      },
    },
    event,
    ...options,
  })
  await popover.present()
  await popover.onDidDismiss()
}

export const dropdownMenu = async (event, options) => {
  return showInPopover(event, Dropdown, {
    options: options.map(option => ({
      ...option,
      handler: () => {
        option.handler()
        popoverController.dismiss()
      },
    })),
  })
}

export const dismissModal = async () => {
  const modal = await modalController.getTop()
  if (modal) {
    return modal.dismiss()
  }
}

export const dismissAllModals = async () => {
  let modal = await modalController.getTop()
  while (modal) {
    await modal.dismiss()
    modal = await modalController.getTop()
  }
}

export default {
  methods: {
    $dialogActive: dialogActive,
    $toast: toast,
    $alert: alert,
    $confirm: confirm,
    $prompt: prompt,
    $actionSheet: actionSheet,
    $showError: showError,
    $showGraphQLErrors: showGraphQLErrors,
    $openMenu: openMenu,
    $closeMenu: closeMenu,
    $showInModal: showInModal,
    $showInPopover: showInPopover,
    $dropdownMenu: dropdownMenu,
    $dismissModal: dismissModal,
    $dismissAllModals: dismissAllModals,
  },
}
