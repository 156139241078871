import { common } from '~gro-components'
import { Singleton } from '~gro-helpers'
import { ref } from 'vue'
import { bridgeQueries, BridgeRepository, growerTypes } from '~gro-modules/Bridge'
import { Storage } from '~gro-plugins'
import { addIcons } from 'ionicons'
import * as allIcons from 'ionicons/icons'

class GrosensComponents extends Singleton {
  constructor () {
    super(GrosensComponents, (instance) => {
      instance._growerType = ref(growerTypes[0])
      instance._region = ref(null)
    })
  }

  get growerType () {
    return this._growerType.value
  }

  get region () {
    return this._region.value
  }

  install (App) {
    Object.keys(common).forEach(name => {
      App.component(name, common[name])
    })
    Object.defineProperty(App.config.globalProperties, '$growerType', {
      get: () => this.growerType,
    })
    Object.defineProperty(App.config.globalProperties, '$region', {
      get: () => this.region,
    })

    App.config.globalProperties.$loadGrowerType = this.loadGrowerType.bind(this)

    const icons = Object.keys(allIcons).reduce((ionSet, icon) => {
      const name = icon.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
      ionSet[name] = allIcons[icon]
      return ionSet
    }, {})
    addIcons(icons)
  }

  async loadRegion () {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) {
      if (!await Storage.has('terms-accepted')) {
        return null
      }
      switch (await Storage.get('terms-accepted')) {
        case 'CA':
        case 'US':
          return 'US'
        case 'UE':
        case 'SA':
        case 'EU':
          return 'EU'
        case 'AU':
        case 'NZ':
          return 'AU'
        case 'RU':
          return 'RU'
        case 'JP':
          return 'AS'
        case 'KR':
          return 'KR'
      }
      return null
    }
    this._region.value = bridge.region
  }

  async loadGrowerType (force = false) {
    if (!force && await Storage.has('grower-type')) {
      return this._setGrowerType(await Storage.get('grower-type'))
    }
    try {
      if (BridgeRepository.current) {
        const growerType = await bridgeQueries.getGrowerType()
        return this._setGrowerType(growerType)
      }
    } catch (e) {
      // .. ignore
    }
    this._growerType.value = growerTypes[0]
    return growerTypes[0]
  }

  async _setGrowerType (growerType) {
    if (!growerType) return
    this._growerType.value = growerType
    await Storage.set('grower-type', growerType)
    return growerType
  }
}

export default new GrosensComponents()
