import i18next from 'i18next'
import deepmerge from 'deepmerge'
import { Singleton } from '~gro-helpers'
import { BridgeRepository, growerTypes } from '~gro-modules/Bridge'
import sharedTranslations from '../lang'
import { GrosensComponents } from '~gro-plugins'
import { ref } from 'vue'

class Translator extends Singleton {
  constructor () {
    super(Translator, (instance) => {
      instance.initialized = false
      instance.language = ref('EN')
      instance.availableLanguages = []
    })
  }

  install (App, appSpecificTranslations = {}) {
    const resources = deepmerge.all([
      sharedTranslations,
      appSpecificTranslations,
    ])

    Object.defineProperty(App.config.globalProperties, '$language', {
      get: () => this.language.value,
    })

    App.config.globalProperties.$t = this.translate.bind(this)
    this.availableLanguages = Object.keys(resources).filter(lang => lang.indexOf('_') === -1)
    const fallbackLng = this.availableLanguages.reduce((fallback, language) => {
      return growerTypes.reduce((fallback, type) => {
        return {
          ...fallback,
          [`${language}_${type}`]: language === 'EN' ? [language] : [`EN_${type}`, language, 'EN'],
        }
      }, fallback)
    }, { default: ['EN'] })
    i18next.init({
      lng: 'EN',
      fallbackLng,
      resources,
    })
    this.initialized = true
  }

  hasKey (key) {
    return i18next.exists(key)
  }

  getKeys (key) {
    return Object.keys(this.translate(key, { returnObjects: true }))
  }

  translate (key, options = {}) {
    if (!this.initialized) throw Error('Translator is not yet initialized. Unable to translate')
    return i18next.t(key, options)
  }

  async reloadLanguage () {
    if (!this.initialized) {
      throw Error('Translator is not yet initialized. Unable to reload language')
    }
    const bridge = await BridgeRepository.getCurrent()
    if (bridge) {
      const language = await bridge.user.getSetting('language', null, true)
      if (language !== null) {
        const growerType = await GrosensComponents.loadGrowerType()
        const tag = growerType ? `${language.toUpperCase()}_${growerType}` : language.toUpperCase()
        this.language.value = tag
        return i18next.changeLanguage(tag)
      }
    }
    return this.guessLng()
  }

  async guessLng () {
    const growerType = await GrosensComponents.loadGrowerType()
    let language = 'EN'
    if (navigator.languages !== undefined) {
      const foundLanguage = navigator.languages.find(language => {
        return this.availableLanguages.includes(this._simplifyLanguageTag(language))
      })
      if (foundLanguage) language = this._simplifyLanguageTag(foundLanguage)
    } else if (
      navigator.language !== undefined &&
      this.availableLanguages.includes(this._simplifyLanguageTag(navigator.language))
    ) {
      language = this._simplifyLanguageTag(navigator.language)
    }

    const tag = growerType ? `${language}_${growerType}` : language
    await i18next.changeLanguage(tag)
    this.language.value = tag
    return language
  }

  _simplifyLanguageTag (tag) {
    const parts = tag.split('-')
    const language = parts[0]
    return language.toUpperCase()
  }
}

window.i18next = i18next
export default new Translator()
