export default {
  methods: {
    $goto (name, params = {}, query = {}) {
      return this.$router.push({
        name,
        params,
        query,
      })
    },

    $goReplace (name, params = {}, query = {}) {
      return this.$router.replace({
        name,
        params,
        query,
      })
    },
    $goBack () {
      return this.$router.back()
    },
  },
}
