import LocalStorage from './storageAdapters/LocalStorage'
import { Singleton } from '../helpers'

/**
 * Unified singleton for accessing storage different instances
 *
 * Interface:
 * - initialize(<storage object>) <== Call with LocalStorage or NativeStorage before using
 *
 * - get(key): mixed
 * - set(key, value): void
 * - has(key): bool
 * - forget(key): void
 * - flush(): void
 */
class Storage extends Singleton {
  constructor () {
    super(Storage, (instance) => {
      if (process.env.NODE_ENV === 'development') {
        window.$storage = instance
      }
    })
  }

  install (App, storage = LocalStorage) {
    this.storage = storage
    window.$log(`%cSTORAGE INITIALIZED %c${storage.type}`, 'font-weight:bold;', '')
    App.config.globalProperties.$storage = this
  }

  async has (key) {
    return this.storage.hasItem(key)
  }

  async get (key, fallback = undefined) {
    const value = await this.storage.getItem(key, fallback)
    window.$log(`%cSTORAGE %c[GET] %c${key}`, 'font-weight:bold;', 'color:green', '', value)
    return value
  }

  async pull (key, fallback = undefined) {
    const value = await this.get(key, fallback)
    await this.forget(key)
    return value
  }

  async set (key, value) {
    window.$log(`%cSTORAGE %c[SET] %c${key}`, 'font-weight:bold;', 'color:blue', '', value)
    return this.storage.setItem(key, value)
  }

  async getFrom (key, id, fallback = undefined, idField = 'serialNumber') {
    const array = await this.get(key, [])
    if (!array.findIndex) throw Error(`Unable to update item in existing array in local storage: ${key} it is not an array`)
    const index = array.findIndex(item => item[idField] === id)
    if (index === -1) return fallback
    return array[index]
  }

  async addTo (key, object) {
    const array = await this.get(key, [])
    if (!array.push) throw Error(`Unable to add item to existing array in local storage: ${key} it is not an array`)
    array.push(object)
    return this.set(key, array)
  }

  async updateIn (key, object, idField = 'serialNumber') {
    const array = await this.get(key, [])
    if (!array.findIndex) throw Error(`Unable to update item in existing array in local storage: ${key} it is not an array`)
    const index = array.findIndex(item => item[idField] === object[idField])
    if (index === -1) return this.addTo(key, object)
    array[index] = { ...array[index], ...object }
    return this.set(key, array)
  }

  async removeFrom (key, object, idField = 'serialNumber') {
    const array = await this.get(key, [])
    if (!array.findIndex) throw Error(`Unable to update item in existing array in local storage: ${key} it is not an array`)
    const index = array.findIndex(item => item[idField] === object[idField])
    if (index === -1) return
    array.splice(index, 1)
    return this.set(key, array)
  }

  async forget (key) {
    window.$log(`%cSTORAGE %c[forget] %c${key}`, 'font-weight:bold;', 'color:orange', '')
    return this.storage.removeItem(key)
  }

  async flush () {
    window.$log('%cSTORAGE %c[flush]', 'font-weight:bold;', 'color:red')
    return this.storage.clear()
  }
}

export default new Storage()
