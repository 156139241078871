import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { ApolloLink } from 'apollo-link'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import ApolloLinkTimeout from 'apollo-link-timeout'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { BridgeRepository } from '~gro-modules/Bridge'
import * as Sentry from '@sentry/vue'

// General headers setup
const httpLink = createHttpLink({
  headers: {
    accept: 'application/json',
  },
})

// General timeout
const timeoutLink = new ApolloLinkTimeout(22.5 * 1000)

// Bridge url
const bridgeLink = setContext(async (_, { headers }) => {
  const bridge = await BridgeRepository.getCurrent()
  if (!bridge) {
    console.debug('No bridge set. Can not access GraphQL api')
    return {}
  }
  return {
    ...headers,
    uri: bridge.getGraphQLUrl(),
    connection: bridge.connection,
  }
})

// Refresh tokens automatically
const tokenRefreshLink = new TokenRefreshLink({
  isTokenValidOrUndefined () {
    const bridge = BridgeRepository.current
    return !!bridge && bridge.user.canAccess()
  },
  async fetchAccessToken () {
    const bridge = await BridgeRepository.getCurrent()
    await bridge.user.loadTokens()
    if (!bridge || !bridge.user.canRefresh()) {
      return {
        status: 401,
        data: {},
      }
    }
    try {
      await bridge.user.refreshTokens()
      return {
        status: 200,
        data: {
          access_token: bridge.user.accessToken,
        },
      }
    } catch (e) {
      _logToSentry('Unable to refresh token', e)
      console.warn(e)
      return {
        status: 500,
        data: {},
      }
    }
  },
  handleError (e) {
    console.error('Error occurred while refreshing access token.', e)
    // BridgeConnect.setConnected(false)

    tokenRefreshLink.fetching = false
    try {
      tokenRefreshLink.queue.consumeQueue()
    } catch (e) {
      _logToSentry('Could not restart queue after auth error', e)
      console.log('Could not restart queue after auth error', e)
    }
  },
  handleResponse: () => response => {
    return response
  },
})

// Add token for current bridge
const authLink = setContext(async (_, { headers }) => {
  const bridge = await BridgeRepository.getCurrent()
  if (!bridge) return {}
  const token = await bridge.user.accessToken
  if (!token) return {}
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
})

// Catch unauthorized errors
const authFailedLink = onError(({ networkError }) => {
  if (!networkError) {
    return
  }
  if (!networkError.response || networkError.response.status !== 401) {
    return
  }
  if (BridgeRepository.current?.user?.canAccess()) {
    _logToSentry('Authentication error', networkError)
  }
  console.error('Authentication error', networkError)
  BridgeRepository.revokeCurrent()
})

// // Track connected state
const connectedLink = new ApolloLink(async (operation, forward) => {
  return forward(operation).map(result => {
    BridgeRepository.current.setConnected()
    return result
  })
})

// Track disconnected state
const disconnectedLink = onError(({ operation, networkError }) => {
  if (!networkError) return
  const context = operation.getContext()
  const bridge = BridgeRepository.current
  if (bridge?.connection !== context.connection || context.dontDisconnect) return

  console.error('Network error', networkError)
  BridgeRepository.registerConnectionIssue()
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
export default new ApolloClient({
  link: ApolloLink.from([
    connectedLink,
    disconnectedLink,
    tokenRefreshLink,
    authFailedLink,
    authLink,
    bridgeLink,
    timeoutLink,
    httpLink,
  ]),
  cache,
  connectToDevTools: (process.env.NODE_ENV === 'development'),

  defaultOptions: {
    mutate: {
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
  },
})

const _logToSentry = function (message, baseError) {
  const newError = new Error(message)
  if (Error.captureStackTrace) {
    Error.captureStackTrace(newError, baseError)
  }
  Sentry.captureException(newError)
}
