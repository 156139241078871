/**
 * Object used to access Localstorage
 * !!Not to be used separately, see the Storage class!!
 */
export default {
  type: 'Local Storage',
  async getItem (key, fallback) {
    return new Promise(function (resolve, reject) {
      const value = window.localStorage.getItem(key)
      if (value === null) {
        if (fallback !== undefined) {
          return resolve(fallback)
        }
        return reject(new Error(`Key [${key}] is not set`))
      }
      try {
        const parsed = JSON.parse(value)
        if (typeof parsed !== 'number') return resolve(parsed)
      } finally {
        resolve(value)
      }
    })
  },
  async hasItem (key) {
    return new Promise(function (resolve) {
      const value = window.localStorage.getItem(key)
      resolve(value !== null)
    })
  },
  async setItem (key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
  },
  async removeItem (key) {
    window.localStorage.removeItem(key)
  },
  async clear () {
    window.localStorage.clear()
  },
}
