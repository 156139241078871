import { Storage } from '~gro-plugins'
import { v4 as uuidv4 } from 'uuid'
import { MiddlewareHelper, Singleton } from '~gro-helpers'
import { BridgeRepository } from '~gro-modules/Bridge'
import { watchEffect } from 'vue'

class Analytics extends Singleton {
  constructor () {
    super(Analytics, instance => {
      instance.enabled = process.env.VUE_APP_SEGMENT_WRITE_KEY
      instance.started = false
      instance.pendingBridgeIdentify = null
      instance.appId = null
      instance.serialNumber = null
    })
  }

  install (App) {
    watchEffect(() => this.identifyBridge(BridgeRepository.current))
    this.identifyBridge(BridgeRepository.current)
    if (!this.enabled) {
      App.config.globalProperties.$track = () => null
      return
    }
    App.config.globalProperties.$track = this.track.bind(this)
    MiddlewareHelper.add('logNavigation', this.logPageNavigation.bind(this))
  }

  async start () {
    if (this.started || !this.enabled) return
    this.started = true

    try {
      const analytics = window.analytics = window.analytics || []
      if (analytics.initialize) return

      analytics.invoked = !0
      analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on']
      analytics.factory = function (t) {
        return function () {
          const e = Array.prototype.slice.call(arguments)
          e.unshift(t)
          analytics.push(e)
          return analytics
        }
      }

      for (let t = 0; t < analytics.methods.length; t++) {
        const e = analytics.methods[t]
        analytics[e] = analytics.factory(e)
      }

      analytics.load = function (t, e) {
        const n = document.createElement('script')
        n.type = 'text/javascript'
        n.async = !0
        n.src = 'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
        const a = document.getElementsByTagName('script')[0]
        a.parentNode.insertBefore(n, a)
        analytics._loadOptions = e
      }

      analytics.SNIPPET_VERSION = '4.10.1'

      this.init()
      analytics.load(process.env.VUE_APP_SEGMENT_WRITE_KEY)
      analytics.page()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Failed to load analytics', e)
    }
  }

  async init () {
    this.appId = await Storage.get('analytics-app-id', null)
    if (!this.appId) {
      this.setAppId(uuidv4())
    } else {
      this.identifyApp()
    }

    this.track('Appstart')

    if (this.pendingBridgeIdentify) {
      this.identifyBridge(this.pendingBridgeIdentify)
      this.pendingBridgeIdentify = null
    }
  }

  async setAppId (appId) {
    this.appId = appId
    if (appId) {
      await Storage.set('analytics-app-id', this.appId)
    }
    this.identifyApp()
  }

  logPageNavigation ({ to }) {
    if (!window.analytics) return
    window.analytics.page({
      path: to.path,
      name: to.name,
      url: to.fullPath,
      properties: {
        params: to.params,
        query: to.query,
        hash: to.hash,
      },
    })
  }

  async identifyApp () {
    try {
      window.analytics.identify(this.appId, {
        source: 'Companion app',
        version: process.env.VUE_APP_VERSION,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Failed to identify app for analytics', e)
    }
  }

  identifyBridge (bridge) {
    if (!bridge) {
      this.serialNumber = null
      return
    }
    if (!this.enabled) return
    if (!this.started) {
      this.pendingBridgeIdentify = bridge
      return
    }

    try {
      this.serialNumber = bridge.serialNumber
      window.analytics.identify(this.appId, {
        bridgeSerialNumber: bridge.serialNumber,
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Failed to identify bridge for analytics', e)
    }
  }

  track (event, properties = {}) {
    if (!window.analytics) return
    properties.bridgeSerialNumber = this.serialNumber

    try {
      window.analytics.track(event, properties)
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn(e)
    }
  }

  async waitForReady () {
    if (!this.enabled) {
      return false
    }
    if (this.started) {
      return true
    }
    return new Promise(resolve => {
      this.waitForReadyCallbacks.push(resolve)
    })
  }

  page (...args) {
    if (!this.enabled || !this.started) {
      return
    }

    try {
      window.analytics.page(...args)
    } catch (e) {
      console.warn(e)
    }
  }
}

export default new Analytics()
