import { ref, computed } from 'vue'
import { Singleton } from '../helpers'
import { authQueries } from '~gro-modules/Auth'
import { Storage } from '~gro-plugins'
import { BridgeRepository } from '~gro-modules/Bridge'
import user from '~gro-modules/Auth/policies'
import bridge from '~gro-modules/Bridge/policies'
import device from '~gro-modules/Device/policies'
import deviceGroup from '~gro-modules/DeviceGroup/policies'

class Gate extends Singleton {
  constructor () {
    super(Gate, (instance) => {
      instance._listeners = []
      instance._currentUser = ref(null)
      instance.definitions = {
        root: {},
        user,
        bridge,
        device,
        deviceGroup,
      }
      if (process.env.NODE_ENV === 'development') {
        window.$user = instance.user
      }
    })
  }

  install (App, definitions = {}) {
    this.definitions = { ...this.definitions, ...definitions }

    App.config.globalProperties.$can = this.can.bind(this)
    App.config.globalProperties.$cannot = this.cannot.bind(this)

    Object.defineProperty(App.config.globalProperties, '$auth', {
      get: () => this.auth,
    })
    Object.defineProperty(App.config.globalProperties, '$guest', {
      get: () => this.guest,
    })
    Object.defineProperty(App.config.globalProperties, '$user', {
      get: () => this.user,
    })
  }

  define (checks, context = 'root') {
    if (!this.definitions[context]) {
      this.definitions[context] = {}
    }
    this.definitions[context] = { ...this.definitions[context], ...checks }
  }

  can () {
    return this.check(...arguments).value
  }

  cannot () {
    return !this.check(...arguments).value
  }

  get auth () {
    return !!this.user
  }

  get guest () {
    return !this.user
  }

  get user () {
    return this._currentUser.value
  }

  set user (user) {
    this._currentUser.value = user
  }

  check (ability, model = null) {
    return computed(() => {
      let context = 'root'
      if (!this.user) {
        console.warn('User is not logged in')
        return false
      }
      if (model) {
        if (typeof model === 'string') {
          context = model
        } else {
          context = Object.keys(model)[0]
        }
      }

      if (this.definitions[context]) {
        if (this.definitions[context][ability]) {
          return this.definitions[context][ability](this.user, model)
        }
        console.warn(`There are no definitions set for [${ability}] in context [${context}]`)
        return false
      }
      console.warn(`There are no definitions set for context [${context}]`)
      return false
    })
  }

  async resetUser () {
    const wasLoggedIn = !!this.user
    if (!wasLoggedIn) {
      await this._assureTokensLoaded()
    }
    const current = await authQueries.getCurrentUser()
    if (current === -1) {
      this.user = null
    } else if (current) {
      this.user = current
    }
    if (wasLoggedIn && !this.user) {
      this.emit('logout')
    } else if (!wasLoggedIn && !!this.user) {
      this.emit('login')
    }
    return this.persist()
  }

  async loadFromCache () {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) return
    const data = Storage.getFrom('bridges', bridge.serialNumber, { user: null })
    this.user = data.user
  }

  async persist () {
    const bridge = await BridgeRepository.getCurrent()
    if (!bridge) return
    return Storage.updateIn('bridges', {
      serialNumber: bridge.serialNumber,
      user: this.user
        ? { role: this.user.role }
        : null,
    })
  }

  on (event, callback) {
    if (!this._listeners[event]) {
      this._listeners[event] = []
    }
    this._listeners[event].push(callback)
  }

  off (event, callback) {
    if (this._listeners[event]) {
      const index = this._listeners[event].indexOf(callback)
      if (index !== -1) {
        this._listeners[event].splice(index, 1)
      }
    }
  }

  emit (event, payload = []) {
    if (this._listeners[event]) {
      this._listeners[event].forEach(callback => callback(null, ...payload))
    }
  }

  async _assureTokensLoaded () {
    const bridge = await BridgeRepository.getCurrent()
    await bridge.user.loadTokens()
  }
}

export default new Gate()
